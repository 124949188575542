<template>
  <b-card>
    <b-form v-if="utilisateur">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nom"
            label-for="nom"
          >
            <b-form-input
              id="nom"
              v-model="utilisateur.nom"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Prénom"
            label-for="prenom"
          >
            <b-form-input
              id="prenom"
              v-model="utilisateur.prenom"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Société"
            label-for="societe"
          >
            <b-form-input
              id="societe"
              v-model="utilisateur.societe"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Telephone"
            label-for="tel"
          >
            <b-form-input
              id="tel"
              v-model="utilisateur.tel"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="utilisateur.email"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email Secondaire"
            label-for="emailSecondaire"
          >
            <b-form-input
              id="emailSecondaire"
              v-model="utilisateur.emailSecondaire"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Role"
            label-for="role"
          >
            <b-form-radio-group
              id="role"
              v-model="role"
              :options="rolesOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Etat"
            label-for="etat"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="etat"
              v-model="utilisateur.etat"
              :options="etatOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="role === 'ROLE_CLIENT'"
          cols="12"
          md="4"
        >

          <b-form-group
            label="Lier à un projet"
            label-for="etat"
            label-class="mb-1"
          >
            <v-select
              v-model="utilisateur.projet"
              label="nom"
              :options="projets"
            />
          </b-form-group>

        </b-col>

        <b-col>
          <b-button @click="update">
            Mettre à jour
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import CryptoJS from 'crypto-js'

const config = require('@/../config')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,
    vSelect,
  },
  data() {
    return {
      id: null,
      utilisateur: null,
      role: 'ROLE_CLIENT',
      etatOptions: [
        { text: 'Actif', value: 'ACTIF' },
        { text: 'Désactivé', value: 'INACTIF' },
      ],
      rolesOptions: [
        { text: 'Administrateur', value: 'ROLE_ADMIN' },
        { text: 'Constructeur', value: 'ROLE_CONSTRUCTEUR' },
        { text: 'Client', value: 'ROLE_CLIENT' },
      ],
      projets: [],
    }
  },
  beforeCreate() {
    const encryptedId = this.$route.params.id

    const bytes = CryptoJS.AES.decrypt(encryptedId, config.crypto_key)
    this.id = bytes.toString(CryptoJS.enc.Utf8)

    this.$store.dispatch('utilisateurs/fetchOne', this.id).then(data => {
      this.role = 'ROLE_CLIENT'
      if (data.roles.includes('ROLE_CONSTRUCTEUR')) this.role = 'ROLE_CONSTRUCTEUR'
      if (data.roles.includes('ROLE_ADMIN')) this.role = 'ROLE_ADMIN'

      this.utilisateur = data
    })

    this.$store.dispatch('projets/fetchAll', { itemsPerPage: 200 }).then(data => {
      this.projets = data.projets.map(item => ({
        '@id': item.value,
        '@type': 'Projet',
        etat: item.etat,
        id: item.id,
        nom: item.projet,
      }))
    })
  },
  methods: {
    update() {
      const data = this.utilisateur

      if (this.role === 'ROLE_CLIENT') data.roles = ['ROLE_CLIENT']
      if (this.role === 'ROLE_CONSTRUCTEUR') data.roles = ['ROLE_CONSTRUCTEUR', 'ROLE_CLIENT']
      if (this.role === 'ROLE_ADMIN') data.roles = ['ROLE_CONSTRUCTEUR', 'ROLE_CLIENT', 'ROLE_ADMIN']

      this.$store.dispatch('utilisateurs/update', data).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification effectuée',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
